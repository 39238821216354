<template>
  <el-select
    v-model="value.subjectAccount"
    filterable
    :placeholder="'请输入关键字'"
    style="width: 100%"
    @change="inputChange"
    :disabled="isDisable"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label + '-' + item.username"
      :value="item.value"
    ></el-option>
  </el-select>
</template>

<script>
import { api } from '/src/api/base';
export const UserSimpleList = api('/user')('user.simple.list.json');

export default {
  props: {
    value: Object,
    type: String,
    // state: String,
    isChecker: Boolean
  },

  data () {
    return {
      options: [],
      isDisable: false
    };
  },
  mounted () {
    if (this.type === '工资包') {
      this.isDisable = true;
    }
    else {
      this.isDisable = false;
    }
    this.remoteMethod(this.value.subjectName);

  },
  methods: {
    delLoan (i) {
      if (this.loanList.length === 1) {
        return this.$message.error('至少保留一个审核人！');
      }
      this.loanList = this.loanList.filter((it, index) => index !== i);

    },
    addLoan () {
      this.loanList.push({
        name: '审批人',
        value: {
          subjectAccount: '',
        },
      });

    },
    inputChange (val) {
      if (this.options.find(it => it.value === val)) {
        this.value.subjectName = this.options.find(
          it => it.value === val
        ).label;
      }
      if (!val) {
        this.options = [];

      }
      this.$emit('change', this.value);
      this.$emit('inputChange');

    },
    async remoteMethod (keywords) {
      if (keywords === '') {
        this.options = [];
        return;
      }
      this.getUserList(keywords);
    },
    async getUserList (keywords) {
      const users = await UserSimpleList({
        pageNumber: 1,
        pageSize: 10,
        keywords,
        isAuthenticated: true,
        // f_in_roles: ['r2', 'r3', 'r4', 'r5'].join(','),
        ...this.isChecker ? { isChecker: true } : {},
        $ignoreRepeat: true
      });
      this.options = users.list.map(it => {
        return {
          ...it,
          label: it.name,
          value: it.code
        };
      });
    },
  }
};
</script>